import React from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { MoreVert } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, TableCell, TableRow } from '@mui/material';
import { RestrictAccess } from 'components';
import {
    collectionStates,
    ReprocessableCollectionStates,
} from 'constants/collections';
import { PATHS } from 'constants/constants';
import { DateTime } from 'luxon';
import { SpecificationInterface } from 'queries/specifications/types';
import { Collection } from 'src/services/api/collections/types';
import { formatTestId } from 'utils/formatTestId/formatTestId';

import CollectionState from './CollectionState/CollectionState';
import Specification from './Specification/Specification';

import styles from './collectionsTableRow.module.scss';

const formatDate = (date: string) =>
    DateTime.fromISO(date).toFormat('dd/LL/yyyy');

interface CollectionsTableRowProps {
    collection: Collection;
    specification: SpecificationInterface | undefined;
    specifications: SpecificationInterface[];
    limit: number; // Used to update the collections table at the right place after the mutation
    offset: number; // Used to update the collections table at the right place after the mutation
}

const CollectionsTableRow = ({
    collection,
    specification,
    specifications,
    limit,
    offset,
}: CollectionsTableRowProps) => {
    const history = useNavigate();
    const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
        null,
    );

    const {
        id: collectionId,
        name,
        referencePeriodStart,
        referencePeriodEnd,
        reference,
    } = collection;

    const { id: collectionStatusId } = collection.state;
    const start = formatDate(referencePeriodStart);
    const end = formatDate(referencePeriodEnd);

    const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setMenuAnchorEl(null);
    };

    const goToReprocessing = (reference: string) => {
        const route = generatePath(PATHS.BULK_PROCESSING, {
            reference,
            collectionId: collectionId.toString(),
        });
        history(route);
    };

    const goToHistoricAmendment = (collectionReference: string) => {
        const route = generatePath(PATHS.HISTORIC_AMENDMENT, {
            collectionReference,
        });
        history(route);
    };

    const canBulkReprocessCollection = (collectionStatusId: number) =>
        ReprocessableCollectionStates.includes(collectionStatusId);

    return (
        <TableRow hover>
            <TableCell>{reference}</TableCell>
            <TableCell>{name}</TableCell>
            <TableCell>
                {start} - {end}
            </TableCell>
            <TableCell>
                <Specification
                    reference={reference}
                    specification={specification}
                    specifications={specifications}
                    collectionState={collectionStatusId}
                    limit={limit}
                    offset={offset}
                />
            </TableCell>
            <TableCell>
                <CollectionState
                    collection={collection}
                    limit={limit}
                    offset={offset}
                />
            </TableCell>
            {collectionId ? (
                <TableCell>
                    <RestrictAccess
                        allowPermissions={[
                            'management.reprocess-for-one-provider',
                        ]}
                    >
                        <IconButton
                            className={styles.menuIcon}
                            disableRipple={true}
                            aria-label="Approve menu"
                            aria-haspopup="true"
                            onClick={openMenu}
                            size="large"
                            data-test-id={formatTestId(
                                'more actions',
                                reference,
                            )}
                        >
                            <MoreVert />
                        </IconButton>

                        <Menu
                            anchorEl={menuAnchorEl}
                            keepMounted
                            open={Boolean(menuAnchorEl)}
                            aria-controls="approve-menu"
                            onClose={closeMenu}
                        >
                            <MenuItem
                                onClick={() => goToReprocessing(reference)}
                                data-test-id={formatTestId(
                                    'reprocess',
                                    reference,
                                )}
                                disabled={
                                    !canBulkReprocessCollection(
                                        collectionStatusId,
                                    )
                                }
                            >
                                Re-process
                            </MenuItem>
                            <MenuItem
                                onClick={() => goToHistoricAmendment(reference)}
                                data-test-id={formatTestId(
                                    'historicAmendment',
                                    reference,
                                )}
                                disabled={
                                    collectionStatusId !==
                                    collectionStates.HISTORIC_AMENDMENT
                                }
                            >
                                Historic Amendment Resubmission
                            </MenuItem>
                        </Menu>
                    </RestrictAccess>
                </TableCell>
            ) : (
                ''
            )}
        </TableRow>
    );
};

export default CollectionsTableRow;
